import styles from '@/components/DistanceLearning/VideoCarousel.module.css'
import extractYoutubeVideoId from '@/utils/extractYoutubeVideoId'
import React from 'react'
import { Carousel } from 'react-bootstrap'

const VideoCarousel = ({ youtubeUrls, autoPlay = false }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  return (
    <Carousel
      interval={null}
      indicators={false}
      onSlid={(number) => setActiveIndex(number)}
      className={styles['video-carousel']}
    >
      {youtubeUrls?.map((url, idx) => (
        <Carousel.Item key={idx}>
          <iframe
            style={{
              aspectRatio: '16/9',
            }}
            src={`https://www.youtube.com/embed/${extractYoutubeVideoId(url)}?${
              activeIndex !== idx && 'modestbranding=0'
            }${
              autoPlay && activeIndex === idx && '&autoplay=1'
            }&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
            title='YouTube video player'
            frameBorder={0}
            allow='autoplay'
            allowFullScreen
            stopVideo
          />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default VideoCarousel
