import VideoCarousel from '@/components/DistanceLearning/VideoCarousel'
import { useStatic } from '@/providers/StaticProvider'
import React from 'react'

const AboutArea = () => {
  const staticData = useStatic()

  const [seeMore, setSeeMore] = React.useState(false)

  if (staticData) {
  }

  return (
    <div className='about-area-three ptb-100'>
      <div className='container'>
        <div className='row align-items-start'>
          <div className='col-lg-5 col-md-12 mb-3'>
            <div className='about-content-box'>
              <h2>শুরু করো তোমার জার্নি এখনি</h2>
              <div style={{ textAlign: 'justify', fontSize: '20px' }}>
                <p className='m-0 ' style={{ fontSize: '20px' }}>
                  ঢাকা থেকে পঞ্চগড়ের বাসের স্পীড কত বাড়ালে আমরা কক্সবাজার পৌছাতে
                  পারব বলো ত?
                </p>
                <p className='m-0 ' style={{ fontSize: '20px' }}>
                  {' '}
                  ০ এর সাথে কত গুণ করলে ১০০ হবে বলো ত?
                </p>
                <p className='m-0 ' style={{ fontSize: '20px' }}>
                  তুমি যদি রাইট ট্র‍্যাকে না থাকো তোমার পরিশ্রম যতগুণ ই বাড়াও
                  ০*১০০=০ আসে।
                </p>
                <p className='m-0 ' style={{ fontSize: '20px' }}>
                  প্রতি বছর হাজারো ভর্তিযোদ্ধা চোখের নিচে কালি নিয়ে, মায়ের চোখের
                  কান্না নিয়ে, বাবার ক্লান্ত বিষন্ন মুখ নিয়ে এই স্বপ্নভংগ এর
                  মিছিলে অসহায় আত্নসমর্পণ করে..
                </p>
                <p className='m-0 ' style={{ fontSize: '20px' }}>
                  স্বপ্নযোদ্ধাদের রাইট ট্র‍্যাকে নিয়ে এসে প্রিসাইজলি প্রিপারেশন
                  এ নিয়ে এসে, জার্নি জুড়ে মেন্টরশীপ এর মধ্যে রেখে আমরা একসাথে
                  তৈরী করেছি
                </p>

                {seeMore && (
                  <div>
                    <p className=' fw-bold m-0 ' style={{ fontSize: '20px' }}>
                      কোথাও চান্স পাচ্ছিনা থেকে একজন রিচিতার 25 দিনে জাবি 40 তম
                      হবার গল্প 🔥
                    </p>
                    <p className='fw-bold m-0 ' style={{ fontSize: '20px' }}>
                      কোথাও চান্স পাচ্ছিনা থেকে একজন নাঈমের এক মাসের জার্নিতে
                      জাবিতে ৮ ম হবার গল্প 🔥
                    </p>

                    <p className=' fw-bold m-0 ' style={{ fontSize: '20px' }}>
                      ঢাকায় স্বপ্ন নিয়ে আসা কিন্ত হাজারো পথে বারবার কনফিউজড হবার
                      হাত থেকে বেচে একজন আনাসের গল্প যে ঢাবি ২৫ তম হয়ে বলল
                      "ভাইয়া আমার ২৫ তম হবার পিছনে ৯০% হাত আপনার "🔥
                    </p>
                    <p className='m-0 ' style={{ fontSize: '20px' }}>
                      একসাথে তৈরী করেছি এমন সব অবাক করা বিজয় গল্প যার লাইনে
                      লাইনে শব্দে শব্দে তুমি শক্তি পাবে 🔥❤
                    </p>
                    <p className='m-0 ' style={{ fontSize: '20px' }}>
                      স্বপ্ন টাকে করে নিতে পারবে অনেক অনেক আপন ❤️ স্বপ্ন যত আপন
                      স্বপ্নযুদ্ধের পরিশ্রম করা ততই সহজ ❤️
                    </p>
                    <p className='m-0 ' style={{ fontSize: '20px' }}>
                      ক্লিক করে দেখে নেও অনেক অনেক শক্তিশালী এই স্বপ্নযুদ্ধের
                      বিজয় গল্প গুলো 🔥❤️
                    </p>
                    <a
                      href='https://m.facebook.com/groups/precisementorship'
                      target='_blank'
                      className='default-btn'
                    >
                      Gift to My Mentor
                    </a>
                  </div>
                )}
                <p
                  className='fw-bold'
                  style={{
                    color: '#f44336',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  See {seeMore ? 'less' : 'more'}
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-7 col-md-12'>
            <VideoCarousel youtubeUrls={staticData?.data?.youtube_video || []} />
          </div>
        </div>
      </div>

      <div className='shape3'>
        <img src='/images/shape3.png' alt='image' />
      </div>
      <div className='shape4'>
        <img src='/images/shape4.png' alt='image' />
      </div>
    </div>
  )
}

export default AboutArea
