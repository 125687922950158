import React from 'react'
import FeedbackSlider from '@/components/DistanceLearning/FeedbackSlider'
import AnnouncementSlider from '@/components/Announcement/AnnouncementSlider'

const index = () => {
  return (
    <div className=''>
      <AnnouncementSlider />
    </div>
  )
}

export default index
